import Head from "next/head";
import MainLayout from "@/layouts/mainLayout";
import styles from "@/styles/Home.module.css";
import Banner from "@/components/Banner";
import Tab from "@/components/tabs";
import News from "@/components/news";
import Image from '@/components/image';

import academicZone from "@/public/assets/images/academicZone1x.png";

import Member from "@/components/member";
import { GetServerSidePropsContext } from "next/types";
import getNav from "@/utils/getNav";

import axios from "@/serve/axios";
import Link from "@/components/link";
import React from "react";
// import getConfig from "next/config";
import getFootLink from "@/utils/getFootLink";
import type { NextPageWithLayout } from "@/types/App";
// const { publicRuntimeConfig } = getConfig();

type Props = {
  bannerData: Banner[];
  // 动态
  pageArticleData: Article[];
  // 活动
  pageActivityData: Activity[];
  // 成员机构
  partnerData: PartnerMap;
} & WebsiteAppProps;

export async function getServerSideProps(context: GetServerSidePropsContext) {
  context.res.setHeader(
    "Cache-Control",
    "public, s-maxage=10, stale-while-revalidate=59"
  );

  const [navigationData, pageActivityData, pageArticleData, partnerData] = await Promise.all(
    [
      axios<null, Nav[]>({ url: "/website/index/navigation", method: 'post' }),
      axios<null, Base.Page<Activity>>({ url: "/website/activity/page", method: 'post', params: { pageIndex: 1, pageSize: 6 }, data: { channelId: '', columnId: '' } }),
      axios<null, Base.Page<Article>>({ url: "/website/article/page", method: 'post', params: { pageIndex: 1, pageSize: 6 }, data: { channelId: '', columnId: '' } }),
      axios<null, PartnerMap>({ url: "/website/partner/map", method: 'post' })
    ]
  )

  const nav = getNav(navigationData, context.resolvedUrl);
  const bannerData = await axios.post<any, Banner[]>("/website/banner/list", {
    bannerPosition: nav?.id,
  });
  const { activityLink, newsLink } = getFootLink(navigationData);
  const websiteInfo = await axios.post<any, any>("/website/siteInfo")
  return {
    props: {
      navigationData: (navigationData || []).map((item) => ({
        link: item.link,
        id: item.id,
        name: item.name,
        navigationList: (item.navigationList || []).map((item) => ({
          id: item.id,
          name: item.name,
          link: item.link,
        })),
      })),
      pageArticleData: pageArticleData ? pageArticleData.records.map((item) => ({
        id: item.id,
        articleName: item.articleName,
        articleAbstract: item.articleAbstract,
        listPicture: item.listPicture,
        publishTime: item.publishTime,
      })) : [],
      pageActivityData: pageActivityData ? pageActivityData.records.map((item) => ({
        id: item.id,
        articleName: item.articleName,
        articleAbstract: item.articleAbstract,
        listPicture: item.listPicture,
        userActivityStatus: item.userActivityStatus,
        miniprogramCode: item.miniprogramCode,
        gmtEnd: item.gmtEnd,
        gmtStart: item.gmtStart,
        type: item.type,
      })) : [],
      partnerData: (Object.keys(partnerData) as MemberKey[]).reduce((prev, key) => ({
        ...prev,
        [key]: partnerData[key].map((item: Partner) => ({ ...item })),
      }), {} as PartnerMap),
      activityLink,
      newsLink,
      bannerData: (bannerData || []).map((item: any) => ({
        id: item.id,
        bannerJumpId: item.bannerJumpId,
        bannerJumpType: item.bannerJumpType,
        bannerOffStationJumpUrl: item.bannerOffStationJumpUrl,
        bannerPictureUrl: item.bannerPictureUrl,
        bannerTitle: item.bannerTitle,
        bannerColorValue: item.bannerColorValue,
      })),
      websiteInfo: {
        copyright: websiteInfo.copyright,
        icpUrl: websiteInfo.icpUrl,
        icpNumber: websiteInfo.icpNumber,
      },
    },
  };
}

const Home: NextPageWithLayout<Props> = ({ bannerData, pageArticleData, pageActivityData, partnerData }) => {
  return (
    <>
      <Banner bannerData={bannerData} />
      <section className={styles.tab_wrapper}>
        <Tab
          tabs={[
            { title: "活动", name: "hd" },
            { title: "动态", name: "dt" },
          ]}
        >
          {({ isAnimation, activeName }) => [
            <Tab.TabPanel
              key="hd"
              value="hd"
              activeName={activeName}
              isAnimation={isAnimation}
            >
              <div className={styles.news_tab_panel}>
                {
                  pageActivityData.map((item) => {
                    return (
                      <News
                        key={item.id}
                        id={item.id}
                        title={item.articleName}
                        content={item.articleAbstract}
                        image={item.listPicture}
                        imageAlt={item.articleName}
                        userActivityStatus={item.userActivityStatus}
                        miniprogramCode={item.miniprogramCode}
                        gmtEnd={item.gmtEnd}
                        gmtStart={item.gmtStart}
                        activityType={item.type}
                        type="activity"
                      />
                    )
                  })
                }
              </div>

            </Tab.TabPanel>,
            <Tab.TabPanel
              key="dt"
              value="dt"
              activeName={activeName}
              isAnimation={isAnimation}
            >

              <div className={styles.news_tab_panel}>
                {
                  pageArticleData.map((item) => {
                    return (
                      <News
                        key={item.id}
                        id={item.id}
                        title={item.articleName}
                        content={item.articleAbstract}
                        image={item.listPicture}
                        imageAlt={item.articleName}
                        friendlyTime={item.publishTime}
                        type="news"
                      />
                    )
                  })
                }
              </div>
            </Tab.TabPanel>,
          ]}
        </Tab>
      </section>
      <section className={styles.Home__academic_zone}>
        <section className={styles.Home_title}>
          <h2>中国罕见病综合云服务平台</h2>
        </section>
        <section className={styles.Home__academic_zone_content}>
          <p>核心产品全面覆盖医疗、监管、研发、医药企业等不同领域，融汇多方需求，提供平台、技术、医学、运维等全流程一体化服务。</p>
          <Image className={styles.image} src={academicZone} alt="中国罕见病综合云服务平台" />
        </section>
      </section>
      <section
        className={styles.Home__about_us}
        style={{
          backgroundImage: `url(/assets/images/about_banner.jpg)`,
        }}
      >
        <section className={styles.Home__about_us_content}>
          <h2>关于我们</h2>
          <p>因为有爱，所以同行！</p>
          <Link href="/about">
            <button className="button paddingbtn">了解详情</button>
          </Link>
        </section>
      </section>
      <section className={styles.Home__member}>
        <section className={styles.Home_title}>
          <h2>成员机构</h2>
        </section>
        <Member dataSource={partnerData} />
      </section>
    </>
  );
};

// 首页页面布局
Home.getLayout = (Page, layoutProps, pageProps) => {
  return (
    <>
      <Head>
        <title>中国罕见病服务平台</title>
        <meta name="robots" content="index,follow" />
        <meta name="renderer" content="webkit" />
        <meta httpEquiv="X-UA-Compatible" content="chrome=1" />
        <link rel="canonical" href="https://www.chard.org.cn/" />
        {/* Open Graph 协议 */}
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="中国罕见病服务平台" />
        <meta property="og:url" content="https://www.chard.org.cn" />
        <meta property="og:image" content="https://hjblm-platform.oss-cn-beijing.aliyuncs.com/rare_disease/2023/11/20231120-112022.jpg" />
        <meta property="og:image:alt" content="中国罕见病服务平台" />
        <meta property="og:width" content="500" />
        <meta property="og:height" content="416" />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="416" />
        <meta property="og:title" content="中国罕见病服务平台" />
        <meta
          property="og:description"
          content="全国诊疗协作机制&多学科专家团队助力罕见病诊疗规范及标准制定，专业罕见病管理工具、规范罕见病临床诊疗与患者管理，教材、指南、共识、诊疗培训、药物研发、科技创新，患者管理、医学运维、教育培训服务和综合信息服务，为我国罕见病患者规范管理与医务人员诊疗能力提升提供一体化解决方案，搭建国际交流平台。"
        />
        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://hjblm-platform.oss-cn-beijing.aliyuncs.com/rare_disease/2023/11/20231120-112022.jpg" />
        <meta name="twitter:image:alt" content="中国罕见病服务平台" />
        <meta
          name="keywords"
          content="中国罕见病联盟官网,中国罕见病联盟,诊疗培训,罕见病新进展,罕见病学术资讯,患者管理,医学运维,教育培训服务,综合信息服务,医生诊疗能力提升,国际罕见病日系列活动,罕见病大会,罕见病领域权威会议,病友故事,关爱使者,医患教育,科普内容,联盟活动,直播活动,病例征集活动"
        />
        <meta
          name="description"
          content="全国诊疗协作机制&多学科专家团队助力罕见病诊疗规范及标准制定，专业罕见病管理工具、规范罕见病临床诊疗与患者管理，教材、指南、共识、诊疗培训、药物研发、科技创新，患者管理、医学运维、教育培训服务和综合信息服务，为我国罕见病患者规范管理与医务人员诊疗能力提升提供一体化解决方案，搭建国际交流平台。"
        />
      </Head>
      <h1 className="visually_hidden" aria-level={1}>中国罕见病服务平台官方网站</h1>
      <MainLayout {...layoutProps}>
        <Page {...pageProps} />
      </MainLayout>
    </>
  );
};


export default Home;